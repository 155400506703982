<template>
  <div class="container">
    <div class="text-center">
      <h2 class="recommended-sellers-block__title">
        {{ $t('menu.best_sales') }}
      </h2>
      <!-- <tooltip :enterable="true" class="width-100" :content="$t('main.best_sales_descriptions')"> -->
      <p class="recommended-sellers-block__description">
        {{ $t('main.best_sales_descriptions') }}
      </p>
      <!-- </tooltip> -->
    </div>

    <!-- <div class="best-selses d-grid">
      <figure class="best-selses__figure h-100 m-0">
        <img
          v-lazy-load
          data-src="/images/best-selses-one.webp"
          class="w-100 best-selses__figure__images"
          alt="Best-selses"
          height="430"
          width="640"
        >
      </figure>
      <figure class="best-selses__figure h-100 m-0">
        <img
          v-lazy-load
          data-src="/images/best-selses-two.webp"
          class="w-100 best-selses__figure__images"
          alt="Best-selses"
          height="430"
          width="640"
        >
      </figure>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'CardBestSales'
}
</script>

<style scoped>
.best-selses {
  grid-template-columns: repeat(2, calc((100% - 20px) / 2));
  grid-gap: 20px;
  margin-top: 27px;
  margin-bottom: 14px;
}

.best-selses__figure__image {
  object-fit: cover;
  object-position: center;
}

@media all and (max-width: 766.9px) {
  .best-selses {
    grid-template-columns: repeat(2, calc((100% - 10px) / 2));
    grid-gap: 10px;
    margin-bottom: 10px;
  }
}
</style>
